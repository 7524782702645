import { 
    defineComponent, 
    onMounted, 
    reactive, 
    ref,
    getCurrentInstance, 
    ComponentInternalInstance 
} from 'vue'
import { useRouter } from 'vue-router'
import { 
    province, 
    companyDetail,
    corporateWelfare,
    upload,
    companyEditeSave
 } from '@/api/index'
import { SET_HIGHLIT, SET_PUBLISH1, SET_PUBLISH2 } from '@/utils/constant'

import './company.less'

export default defineComponent({
    name: 'Publish',
    setup () {

        onMounted(() => {
            _province()
            _companyDetail()
        })

        const router = useRouter()
        

        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const state: any = reactive({
            cascaderShow: false,
            cascaderValue: '',
            citys: [], //城市
            citysCode: '',
            form: { } ,//表单
            company: {
                logo: 'https://img.fanwoon.com/avatar.png'
            },
            welfare: [], //福利
            isNext: true,
            fileList: [],
            copyfileList: [],
            morePic: []
        })
        const show = ref(true) // 是否显示已有复制的职位

        //自定义的省市区
        const fieldNames = {
            text: 'name',
            value: 'id',
            children: 'children',
        };

        //初始化公司福利
        const _corporateWelfare = async () => {
            const res: any = await corporateWelfare()

            let welfare: any = []
            if (state.company.corporateWelfare) {
                welfare = state.company.corporateWelfare.split(",")
            }
           
            res.data.forEach((item: any) => {
                item['checked'] = false
                welfare.forEach((ite: any) => {
                    if (item.name === ite) {
                        item['checked'] = true
                    }
                })
            })
            state.welfare = res.data
        }

        //获取企业详情
        const _companyDetail = async () => {
            const res: any = await companyDetail()
            state.company = res.data
            state.citysCode = res.data.districtId
            console.log(res.data.gallery)
            if (res.data.gallery) {
                const gallery =  res.data.gallery.split(",")
                const data: any = []

                gallery.forEach((item: any) => {
                    data.push({ url: item })
                    state.morePic.push({ url: item })
                })
                state.fileList = data;
            }

            console.log(res)
            _corporateWelfare()

        }

       const onSubmit = async () => {
           //debugger
            if (!state.company.shortName) {
                return proxy.$toast('请输入线下门店牌匾名!');
            }
            if (!state.company.introduction) {
                return proxy.$toast('请输入门店介绍!');
            }
            if (state.fileList.length === 0) {
                return proxy.$toast('请上传门店展示图!');
            }

            let showimg = state.morePic.map((item: any) => item.url)
            if (showimg.length > 0) {
                showimg = showimg.join(",")
                state.company.gallery = showimg
            }

            if (!state.isNext) return

            if (state.welfare.length > 0) {
                const welfare: any = []
                state.welfare.forEach((item: any) => {
                    if (item.checked) {
                        welfare.push(item.name)
                    }
                })
                console.log(welfare.join(","))
                state.company.corporateWelfare = welfare.join(",")
            }
            state.isNext = true

            //return 
            const res: any = await companyEditeSave(state.company)
            state.isNext = true
            if (res.code === 0) {
                return proxy.$toast('公司信息信息修改成功!');
            }
            console.log(state.company, '打印了。。。')
       }

        // 取消
        const onCancel = () => {
            router.push({path: '/personal'})
        }
        

        //省市区
        const _province = async () => {
            const res: any = await province()
            state.citys = res.data
            console.log(res, 'shengshiqu')
        }

        //关闭省市区弹框
        const onCloseCitys = () => {
            state.cascaderShow = false
        }

        const onCloseCityChange = (obj: any) => {
            const arry = obj.selectedOptions
            if (arry.length === 3) {
                state.company.provinceId = arry[0].id
                state.company.province = arry[0].name
                state.company.cityId = arry[1].id
                state.company.city = arry[1].name
                state.company.districtId = arry[2].id
                state.company.district = arry[2].name
            }
        }

        const onCheckeWelfare = (item: any) => {
            item.checked = !item.checked
        }

        const onAfterRead = async (e: any) => {
            const file = e.file
            const formData = new FormData(); // 创建form对象
            formData.append('file', file)
            const res: any = await upload(formData, 1)
            state.company.logo = res.data
        }

        const onAfterReadMore = async (e: any) => {
            state.copyfileList = state.fileList

            if (state.fileList.length === 5)return
            const file = e.file

            const formData = new FormData(); // 创建form对象
            formData.append('file', file)
            const res: any = await upload(formData, 1)
            state.morePic.push( { url: res.data })
        }

        const onDeleteReadMore = async (e: any) => {

            const url = e.url 
            //代表是数据流形式
            if (!url) {
                state.copyfileList.forEach((item: any, index: number) => {
                     if (item.file && item.file.name === e.file.name) {
                        state.copyfileList.splice(index, 1)
                        state.morePic.splice(index, 1)
                        state.fileList.splice(index, 1)
                     }
                })
                return 
            }
            
            state.morePic.forEach((item: any, index: number) => {
                 if (item.url == url) {
                     state.morePic.splice(index, 1)
                     state.fileList.splice(index, 1)
                 }
            })
        }

        return () => (
            <div class="publish-page">
                <div class="container-box">
                <div class="header-box flex-box justify-content-between">
                    <div>编辑门店信息</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                
                <ul class="list-item">
                    <li class="flex-box align-items-center">
                        <div>
                            <img class="company-logo" src={ state.company.logo } />
                        </div>
                        <div>
                        <van-uploader after-read={ onAfterRead } result-type="file">
                            <span class="up-logo">修改LOGO</span>
                        </van-uploader>
                        </div>
                    </li>
                    <li class="item">
                        <div class="mt57 mb30">企业名称：<span>{ state.company.name }</span></div>
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>线下门店牌匾名</div>
                            <div class="block-input">
                                <input class="input" readonly={!show.value ? true : false} v-model={ state.company.shortName } placeholder="翻腕房产（人广店）" />
                            </div>
                        </div>
                        <div class="sub-item mt21">
                            <div class="title mb15">门店福利</div>
                            <div class="fuli-box">
                                { 
                                    !state.welfare ? '' : (
                                        state.welfare.map((item: any) => {
                                            return (
                                                <div class={`fuli-item ${ item.checked ? 'active-item' : '' }`} 
                                                    key={ item.id } 
                                                    onClick={ ()=> onCheckeWelfare(item) }
                                                    >
                                                        { item.name }
                                                </div>
                                            )
                                        })
                                    )
                                }
                            </div>
                        </div>
                        <div class="sub-item mt21">
                            <div class="title mb15"><label>*</label>门店介绍</div>
                            <div class="block-textarea">
                                <textarea class="input" readonly={!show.value ? true : false} v-model={ state.company.introduction } placeholder="翻腕房产（人广店）" />
                            </div>
                        </div>
                        <div class="sub-item mt21">
                            <div class="title mb15"><label>*</label>门店展示</div>
                            <van-uploader 
                                v-model={ state.fileList } 
                                after-read={ onAfterReadMore }  
                                before-delete={ onDeleteReadMore }
                                multiple 
                                max-count="5" 
                            />
                        </div>

                        
                        
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>地址</div>
                            <div class="flex-box justify-content-between align-items-center">
                                
                                {
                                    show.value ? (
                                        <div onClick={ () => state.cascaderShow = true } class="flex-box justify-content-between align-items-center gutter w390 block-input">
                                            <div class="citys-first-box">
                                                {
                                                    (!state.company.province && !state.company.city && !state.company.district) ? '请选择省份和城市' : 
                                                    state.company.province + '/' + state.company.city  + '/' + state.company.district
                                                }
                                            </div>
                                            <div><van-icon name="arrow-down" /></div>
                                        </div>
                                    ) : (
                                        <div class="flex-box justify-content-between align-items-center gutter w390 block-input">
                                            <div class="citys-first-box">
                                                {
                                                    (!state.form.province && !state.form.city && !state.form.district) ? '请选择省份和城市' : 
                                                    state.form.province + '/' + state.form.city  + '/' + state.form.district
                                                }
                                            </div>
                                            <div><van-icon name="arrow-down" /></div>
                                        </div>
                                    )
                                }
                                
                                
                                <div class="block-input wl11">
                                    <input class="input-item address-box" readonly={!show.value ? true : false} v-model={ state.company.hqAdd } placeholder="请输入详细地址，不超过20个字符" />
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                </div>
                <div class="footer-item">
                    <button class="cancel-btn" onClick={onCancel}>取消</button>
                    <button class="next-btn" onClick={onSubmit}>提交</button>
                </div>

                <van-popup 
                    show={ state.cascaderShow } 
                    get-container="#app" 
                   
                    position="bottom"
                    >
                    <van-cascader 
                        v-model={ state.citysCode }
                        title="请选择所在地区" 
                        options={ state.citys }
                        field-names={ fieldNames }
                        onClose= { onCloseCitys } 
                        onChange= { onCloseCityChange }
                        />
                </van-popup>
                
            </div>
        )
    }
})